<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
        		Welcome to <a href="https://waproapk.net/">https://waproapk.net/</a>. We offer a secure and reliable platform designed to enhance your messaging experience. With our app, you can enjoy custom features and personalized privacy settings. This fosters a more connected and harmonious world, strengthening communication between people globally.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'Our goal is to provide you with a clear understanding of our services, enabling you to navigate the site with confidence and peace of mind.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  